import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "custom-axios-instance"
    }}>{`Custom Axios Instance`}</h1>
    <p>{`You can define a custom `}<a parentName="p" {...{
        "href": "https://github.com/axios/axios#creating-an-instance"
      }}>{`axios instance`}</a>{` to be used for a request.`}</p>
    <p>{`This is useful if your app needs to interact with multiple APIs, each having its own unique set of request options.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <ol>
      <li parentName="ol">{`Define your axios instance (`}<a parentName="li" {...{
          "href": "https://github.com/axios/axios#creating-an-instance"
        }}>{`see axios docs`}</a>{`):`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const instance = axios.create({
  baseURL: 'https://example/api',
  authorization: 'Bearer YWxhZGRpbjpvcGVuc2VzYW1l',
});
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Pass it to a hook using the `}<inlineCode parentName="li">{`axiosInstance`}</inlineCode>{` option:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { data, error, loading } = useAxios({
  axiosInstance: instance,
  url: '/animals/2',
});
`}</code></pre>
    <p>{`All requests made will use the provided axios instance.`}</p>
    <p>{`If you need to use the same `}<inlineCode parentName="p">{`instance`}</inlineCode>{` across requests that live in different components, create a module that exports the instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// apiInstance.js
const instance = axios.create({
  baseURL: 'https://example/api',
  authorization: 'Bearer YWxhZGRpbjpvcGVuc2VzYW1l',
});

export default instance;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      